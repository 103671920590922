import { isDefined } from "@clipboard-health/util-ts";
import { differenceInDays, parseISO } from "date-fns";

import { NUMBER_OF_DAYS_TO_SHOW_SOON_TO_EXPIRE_NOTICE } from "../Documents/constants";
import { DocumentRequirementType, type HcpRequirement } from "../Documents/types";

export function getIsExpiringSoon(
  requirement: HcpRequirement,
  requirementType: DocumentRequirementType
): boolean {
  return (
    requirementType === DocumentRequirementType.COMPLETED &&
    isDefined(requirement.expiry) &&
    differenceInDays(parseISO(requirement.expiry), new Date()) <=
      NUMBER_OF_DAYS_TO_SHOW_SOON_TO_EXPIRE_NOTICE
  );
}
