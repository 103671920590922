import { isDefined } from "@clipboard-health/util-ts";

import { DocumentRequirementType, type HcpRequirementStatus } from "../Documents/types";

interface GetRequirementTypeProps {
  requirementId?: string;
  hcpRequirementStatus?: HcpRequirementStatus;
}

export function getRequirementType(
  props: GetRequirementTypeProps
): DocumentRequirementType | undefined {
  const { requirementId, hcpRequirementStatus } = props;

  if (!isDefined(requirementId) || !isDefined(hcpRequirementStatus)) {
    return undefined;
  }

  return Object.values(DocumentRequirementType).find((requirementTypeKey) =>
    hcpRequirementStatus[requirementTypeKey]?.includes(requirementId)
  );
}
