import { type HcpRequirement, MappingLevel } from "../Documents/types";

// TODO: This is a hack to get around the fact that the API is returning duplicate requirements with different mapping levels
const MAPPING_LEVEL_PRIORITY: Record<MappingLevel, number> = {
  [MappingLevel.HCF]: 0,
  [MappingLevel.COUNTY]: 1,
  [MappingLevel.MSA]: 2,
  [MappingLevel.STATE]: 3,
  [MappingLevel.GLOBAL]: 4,
};

export function getPriorityStatusRequirementsById(
  requirements: HcpRequirement[]
): Record<string, HcpRequirement> {
  const statusRequirementsById: Record<string, HcpRequirement> = {};

  requirements.forEach((requirement) => {
    if (statusRequirementsById[requirement.reqId]) {
      const currentMappingLevel: MappingLevel = statusRequirementsById[requirement.reqId].level;
      const requirementLevelPriority = MAPPING_LEVEL_PRIORITY[requirement.level] ?? -1;
      const statusRequirementLevelPriority = MAPPING_LEVEL_PRIORITY[currentMappingLevel] ?? -1;

      if (requirementLevelPriority < statusRequirementLevelPriority) {
        return;
      }
    }

    statusRequirementsById[requirement.reqId] = requirement;
  });

  return statusRequirementsById;
}
