import pluralize from "pluralize";

import { DocumentRequirementType } from "../Documents/types";

export function getDescriptionForRequirementType(
  requirementType: DocumentRequirementType,
  requirementCount: number
) {
  if (
    requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES ||
    requirementType === DocumentRequirementType.MISSING
  ) {
    return `To book shifts, please complete the ${pluralize(
      "requirement",
      requirementCount
    )} listed below. You may need to upload a document or sign a form`;
  }

  if (requirementType === DocumentRequirementType.OPTIONAL) {
    const requirementsText =
      requirementCount === 1 ? "This requirement is" : "These requirements are";
    return `${requirementsText} not necessary to book a shift, but it’s helpful for the facility to have. Please complete if you have it available.`;
  }

  return undefined;
}
