import { type OpenShiftCountResponse } from "@src/appV2/OpenShifts/api/useOpenShiftCount";

import { type HcpRequirement } from "../Documents/types";

interface Props {
  openShiftCountData: OpenShiftCountResponse;
  requirements: HcpRequirement[];
}

interface RequirementResult {
  potentialShiftsCount: number;
  potentialFacilitiesCount: number;
}

export function getPotentialShiftsAndFacilitiesForRequirements(
  props: Props
): Map<string, RequirementResult> {
  const { openShiftCountData, requirements } = props;

  const slots = Object.values(openShiftCountData).flatMap((openShiftDay) => openShiftDay.slots);

  const facilityShiftCounts = new Map<string, number>();
  for (const slot of slots) {
    facilityShiftCounts.set(slot.facilityId, (facilityShiftCounts.get(slot.facilityId) ?? 0) + 1);
  }

  const results = new Map<string, RequirementResult>();

  for (const requirement of requirements) {
    let potentialShiftsCount = 0;
    const potentialFacilities = new Set<string>();

    facilityShiftCounts.forEach((shiftCount, facilityId) => {
      if (requirement.requiredBy?.includes(facilityId)) {
        potentialShiftsCount += shiftCount;
        potentialFacilities.add(facilityId);
      }
    });

    results.set(requirement.reqId, {
      potentialShiftsCount,
      potentialFacilitiesCount: potentialFacilities.size,
    });
  }

  return results;
}
