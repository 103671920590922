import AvTimerIcon from "@mui/icons-material/AvTimer";
import { Box } from "@mui/material";
import { type ReactElement } from "react";

export function AccountsDocumentPendingIcon(): ReactElement {
  return (
    <Box
      component="span"
      sx={{
        color: (theme) => theme.palette.grey[600],
      }}
    >
      <AvTimerIcon color="inherit" />
    </Box>
  );
}
