import { isDefined } from "@clipboard-health/util-ts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { type ReactElement } from "react";

import { REQUIREMENT_STATUS_DATA } from "../Documents/constants";
import {
  type CheckrApplicationStatusResponse,
  CheckrInvitationStatus,
  CheckrReportStatus,
  DocumentRequirementType,
} from "../Documents/types";
import {
  type GetCheckrRequirementStatus,
  getCheckrRequirementStatus,
} from "./getCheckrRequirementStatus";

export interface GetCheckrRequirementParams {
  documentRequirementType: DocumentRequirementType;
  isExpiringSoon: boolean;
  checkrApplicationStatusResponse?: CheckrApplicationStatusResponse;
}

interface CheckrRequirementReturnType {
  statusIcon?: ReactElement;
  checkrRequirementStatus: GetCheckrRequirementStatus | undefined;
  checkrRequirementIsDisabled: boolean;
  checkrRequirementIsCompleted: boolean;
}

export function getCheckrRequirement(
  params: GetCheckrRequirementParams
): CheckrRequirementReturnType {
  const { documentRequirementType, isExpiringSoon, checkrApplicationStatusResponse } = params;

  let checkrRequirementStatus: GetCheckrRequirementStatus | undefined;

  if (isDefined(checkrApplicationStatusResponse)) {
    checkrRequirementStatus = getCheckrRequirementStatus({
      requirementType: documentRequirementType,
      checkrApplicationStatus: checkrApplicationStatusResponse.data,
      isCheckrRequirementAlmostExpired: isExpiringSoon,
    });
  }

  const { icon } = REQUIREMENT_STATUS_DATA[documentRequirementType];
  let statusIcon = icon;

  if (isExpiringSoon) {
    statusIcon = <ReportProblemIcon color="warning" />;
  }

  if (isDefined(checkrRequirementStatus) && isDefined(checkrRequirementStatus?.icon)) {
    statusIcon = checkrRequirementStatus.icon;
  }

  const checkrRequirementIsCompleted =
    documentRequirementType === DocumentRequirementType.COMPLETED &&
    isDefined(checkrApplicationStatusResponse) &&
    checkrApplicationStatusResponse.data.invitationStatus === CheckrInvitationStatus.COMPLETED &&
    !isExpiringSoon;

  const checkrRequirementIsDisabled =
    (isDefined(checkrApplicationStatusResponse) &&
      (checkrApplicationStatusResponse.data.invitationStatus === CheckrInvitationStatus.PENDING ||
        checkrApplicationStatusResponse.data.reportStatus === CheckrReportStatus.PENDING)) ||
    documentRequirementType === DocumentRequirementType.PENDING;

  return {
    statusIcon,
    checkrRequirementStatus,
    checkrRequirementIsDisabled,
    checkrRequirementIsCompleted,
  };
}
