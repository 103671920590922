import { type HcpRequirement } from "../Documents/types";

export function filterVisibleRequirements(
  requirementIds: string[],
  requirementStatus: Record<string, HcpRequirement>
) {
  return requirementIds.filter((requirementId) =>
    Boolean(requirementStatus[requirementId]?.visibleToHCP)
  );
}
