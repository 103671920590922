import { isDefined } from "@clipboard-health/util-ts";
import { type ReactElement } from "react";

import {
  type CheckrApplicationStatus,
  CheckrInvitationStatus,
  DocumentRequirementType,
} from "../Documents/types";
import { AccountsDocumentPendingIcon } from "../statusIcons";

interface CheckrRequirementStatusType {
  description?: string;
  linkText?: string;
  icon?: ReactElement;
  invitationSent?: {
    description: string;
    icon?: ReactElement;
  };
  invitationNotSent?: {
    description: string;
    linkText?: string;
    icon?: ReactElement;
  };
  almostExpiredInvitationNotSent?: {
    linkText?: string;
    icon?: ReactElement;
  };
  almostExpiredInvitationSent?: {
    description: string;
  };
}

const CheckrRequirementStatus: Record<string, CheckrRequirementStatusType> = {
  [DocumentRequirementType.EXPIRED]: {
    invitationSent: {
      description:
        "Invite sent! Check your messages, inbox and spam folder for a message from Checkr.",
    },
    invitationNotSent: {
      description: "Document Expired!",
      linkText: "Tap to request an invite.",
    },
  },
  [DocumentRequirementType.REJECTED]: {
    description: "Document Rejected!",
    linkText: "Tap to learn more.",
  },
  [DocumentRequirementType.PENDING]: {
    description: "Clipboard is waiting for your results.",
    icon: <AccountsDocumentPendingIcon />,
  },
  [DocumentRequirementType.MISSING]: {
    invitationSent: {
      description:
        "Invite sent! Check your messages, inbox and spam folder for a message from Checkr.",
      icon: <AccountsDocumentPendingIcon />,
    },
    invitationNotSent: {
      description: "You must initiate your background check.",
      linkText: "Tap here to request an invite.",
    },
  },
  [DocumentRequirementType.OPTIONAL]: {
    invitationSent: {
      description:
        "Invite sent! Check your messages, inbox and spam folder for a message from Checkr.",
      icon: <AccountsDocumentPendingIcon />,
    },
    invitationNotSent: {
      description: "You must initiate your background check.",
      linkText: "Tap here to request an invite.",
    },
  },
  [DocumentRequirementType.COMPLETED]: {
    almostExpiredInvitationNotSent: {
      linkText: "Tap to request an invite.",
    },
    almostExpiredInvitationSent: {
      description:
        "Invite sent! Check your messages, inbox and spam folder for a message from Checkr.",
    },
  },
} as const;

interface CheckrRequirementStatusParams {
  requirementType: DocumentRequirementType;
  checkrApplicationStatus: CheckrApplicationStatus;
  isCheckrRequirementAlmostExpired: boolean;
}

export interface GetCheckrRequirementStatus {
  description?: string;
  linkText?: string;
  icon?: ReactElement;
}

export function getCheckrRequirementStatus(
  params: CheckrRequirementStatusParams
): GetCheckrRequirementStatus | undefined {
  const { requirementType, checkrApplicationStatus, isCheckrRequirementAlmostExpired } = params;
  const { invitationStatus } = checkrApplicationStatus;

  const requirementData = CheckrRequirementStatus[requirementType];

  if (!isDefined(requirementData)) {
    return undefined;
  }

  switch (requirementType) {
    case DocumentRequirementType.EXPIRED:
    case DocumentRequirementType.OPTIONAL:
    case DocumentRequirementType.MISSING: {
      if (invitationStatus === CheckrInvitationStatus.PENDING) {
        return requirementData.invitationSent;
      }

      if (invitationStatus === CheckrInvitationStatus.COMPLETED) {
        return undefined;
      }

      return requirementData?.invitationNotSent;
    }

    case DocumentRequirementType.COMPLETED: {
      if (isCheckrRequirementAlmostExpired) {
        if (invitationStatus === CheckrInvitationStatus.PENDING) {
          return requirementData.almostExpiredInvitationSent;
        }

        return requirementData.almostExpiredInvitationNotSent;
      }

      return undefined;
    }

    default: {
      return requirementData;
    }
  }
}
