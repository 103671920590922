import { isDefined } from "@clipboard-health/util-ts";

import { CHECKR_REQUIREMENT_ID } from "../Documents/constants";
import {
  type CheckrApplicationStatusResponse,
  type GetFilteredDocumentsApiResponse,
  type HcpRequirementStatus,
} from "../Documents/types";
import { updateStatusForCheckrRequirements } from "./updateStatusForCheckrRequirements";

interface GetFinalHcpRequirementStatusParams {
  hcpRequirementStatus: HcpRequirementStatus;
  getFilteredDocumentsApiResponse?: GetFilteredDocumentsApiResponse;
  checkrApplicationStatus?: CheckrApplicationStatusResponse;
}

export function getFinalHcpRequirementStatus(params: GetFinalHcpRequirementStatusParams) {
  const { hcpRequirementStatus, getFilteredDocumentsApiResponse, checkrApplicationStatus } = params;

  if (
    Object.keys(hcpRequirementStatus.requirements).includes(CHECKR_REQUIREMENT_ID) &&
    isDefined(checkrApplicationStatus) &&
    isDefined(getFilteredDocumentsApiResponse) &&
    isDefined(getFilteredDocumentsApiResponse.data.documentList) &&
    isDefined(hcpRequirementStatus)
  ) {
    return updateStatusForCheckrRequirements({
      hcpRequirementStatus,
      checkrApplicationStatus: checkrApplicationStatus?.data,
      rejectedDocuments: getFilteredDocumentsApiResponse.data.documentList ?? [],
    });
  }

  return hcpRequirementStatus;
}
